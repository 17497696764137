.logotype {
  position: absolute;
  top: 103px;
  left: 72px;
  width: 64px;
  height: 64px;
  transition: transform 0.3s ease-in-out;
  z-index: 15;

  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(1.05);
  }

  @media (max-width: 3839px) {
    width: 32px;
    height: 32px;
  }

  @media (max-width: 1599px) {
    top: 52px;
    width: 24px;
    height: 24px;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.logotypeImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
