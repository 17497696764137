.wrapper {
  position: relative;
  min-height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  background-color: var(--darkgray);
  overflow: hidden;

  @media (max-width: (3839px)) {
  }
  @media (max-width: (2159px)) {
  }
  @media (max-width: (1599px)) {
  }
  @media (max-width: (1199px)) {
  }
  @media (max-width: (767px)) {
  }
}

.roundAnimationText {
  position: absolute;
  left: 204px;
  top: 36px;
  width: 320px;
  height: 320px;
  background-image: url("../../../public/content/round-text.svg");
  background-size: contain;
  background-repeat: no-repeat;
  animation-name: spin;
  animation-duration: 10000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  backface-visibility: hidden;
  transform: translateZ(0);
  image-rendering: -webkit-optimize-contrast;
  will-change: transform;
  image-rendering: pixelated;
  z-index: 1;

  @media (max-width: (3839px)) {
    width: 200px;
    height: 200px;
    left: 124px;
    top: 26px;
  }

  @media (max-width: (2159px)) {
    left: 88px;
    top: 26px;
    width: 200px;
    height: 200px;
  }

  @media (max-width: (1599px)) {
    left: 81px;
    top: 49px;
    width: 140px;
    height: 140px;
  }

  @media (max-width: (1199px)) {
    top: 22px;
  }

  @media (max-width: (767px)) {
    top: inherit;
    left: inherit;
    bottom: 55px;
    right: 5vw;
    width: 50vw;
    height: 50vw;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.contentLeft {
  position: absolute;
  top: 171px;
  left: 364px;
  z-index: 7;

  @media (max-width: (3839px)) {
    top: 100px;
    left: 224px;
  }
  @media (max-width: (2159px)) {
    left: 177px;
  }
  @media (max-width: (1599px)) {
    left: 144px;
  }
  @media (max-width: (1199px)) {
    top: 78px;
  }
  @media (max-width: (767px)) {
    top: 50px;
    left: 27px;
  }
}
.back > h2 {
  font: var(--bodyP1);
  font-feature-settings: "cv11" on, "cv02" on, "cv03" on, "cv04" on;
  color: var(--white);
  z-index: 2;

  @media (max-width: (3839px)) {
    font: var(--bodyP3);
  }
  @media (max-width: (2159px)) {
    font: var(--bodyP2);
  }
  @media (max-width: (1599px)) {
    font: var(--bodyP3);
  }
}
.back > h2 > span {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: -1.7em;
    left: 0;
    width: 1.9em;
    height: 1em;
    transform: rotate(180deg);
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../../../public/content/arrow.svg");
  }
  &::before {
    content: "";
    position: absolute;
    top: -1.5em;
    left: 0;
    width: 8em;
    height: 2em;
    cursor: pointer;
    z-index: 6;
  }
}

.title {
  font: var(--headingFourth);
  font-feature-settings: "cv11" on, "cv02" on, "cv03" on, "cv04" on;
  color: var(--white);

  @media (max-width: (3839px)) {
    font: var(--headingFifth);
  }

  @media (max-width: (2159px)) {
    font: var(--headingSixth);
  }

  @media (max-width: (1599px)) {
    font: var(--headingSeventh);
  }
}
.leftImage {
  position: absolute;
  left: 10vw;
  top: 0;
  width: 70vh;
  height: 65vh;
  object-fit: contain;
  object-position: center;
  z-index: 5;
  opacity: 1;

  @media (max-width: (2159px)) {
    top: 3vh;
  }
  @media (max-width: (1599px)) {
    top: 0;
  }
  @media (max-width: (1199px)) {
    left: 72px;
    top: 123px;
    width: 480px;
    height: 480px;
  }
  @media (max-width: (767px)) {
    left: 0;
    top: inherit;
    bottom: 0;
    width: 100vw;
    height: 100vw;
  }
}

.contentRight {
  position: absolute;
  top: calc(var(--vh, 1vh) * 15);
  right: 72px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 5%;
  width: 50vw;
  height: calc(var(--vh, 1vh) * 45);
  z-index: 6;

  @media (max-width: (1199px)) {
    width: 240px;
    height: 376px;
  }

  @media (max-width: (767px)) {
    top: calc(var(--vh, 1vh) * 15);
    right: 24px;
    width: calc(100vw - 48px);
    height: calc(var(--vh, 1vh) * 36);
  }
}

.itemsBlockWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  box-sizing: border-box;
  width: 1761px;
  height: 720px;
  gap: 96px;
  z-index: 5;

  @media (max-width: (3839px)) {
    width: 777px;
    height: 360px;
    gap: 48px;
  }
  @media (max-width: (2159px)) {
    width: 622px;
    height: 288px;
    gap: 38px;
  }
  @media (max-width: (1599px)) {
    width: 518px;
    height: 240px;
    gap: calc(var(--vh, 1vh) * 2);
  }
  @media (max-width: (1199px)) {
    left: 555px;
    top: 135px;
    flex-direction: column;
    width: 144px;
    height: 376px;
    margin-left: auto;
  }
  @media (max-width: (767px)) {
    left: 0px;
    top: 120px;
    flex-direction: row;
    justify-content: space-between;
    width: 100vw;
    height: 216px;
    padding: 0 27px;
    gap: 0px;
  }
}
.itemsBlockContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 461px;
  height: 312px;
  box-sizing: border-box;
  gap: 24px;
  cursor: pointer;

  @media (max-width: (3839px)) {
    width: 216px;
    height: 156px;
    gap: 12px;
  }
  @media (max-width: (2159px)) {
    width: 173px;
    height: 125px;
    gap: 9.6px;
  }
  @media (max-width: (1599px)) {
    width: 144px;
    height: 104px;
    gap: 8px;
  }
  @media (max-width: (1199px)) {
    &:nth-child(n + 4) {
      display: none;
    }
  }
}
.itemsBlockImg {
  position: relative;
  min-width: 96px;
  min-height: 96px;
  transition: filter 0.5s ease-in-out;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../../../public/content/animationStar/avaround.svg");

  @media (max-width: (3839px)) {
    min-width: 72px;
    min-height: 72px;
  }
  @media (max-width: (2159px)) {
    min-width: 58px;
    min-height: 58px;
  }
  @media (max-width: (1599px)) {
    min-width: 48px;
    min-height: 48px;
  }
}
.itemsBlockContainer:hover .itemsBlockImg {
  filter: brightness(0) invert(1);
}
.itemsBlockImg::after {
  content: "";
  position: absolute;
  left: 0;
  top: 15%;
  width: 0;
  height: 0;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0;
  background-image: url("../../../public/content/animationStar/star-small.svg");
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 2;
}
.itemsBlockContainer:hover .itemsBlockImg::after {
  width: 30%;
  height: 30%;
  transform: translate(600%, 0);
  opacity: 1;
}
.itemsBlockImg::before {
  content: "";
  position: absolute;
  top: 15%;
  left: 0;
  width: 0;
  height: 0;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0;
  background-image: url("../../../public/content/animationStar/star-8\(thick\).svg");
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 2;
}
.itemsBlockContainer:hover .itemsBlockImg::before {
  width: 30%;
  height: 30%;
  transform: translate(700%, 150%);
  opacity: 1;
}
.itemsBlockImgStar {
  position: absolute;
  top: 0em;
  left: 0;
  width: 0;
  height: 0;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0;
  background-image: url("../../../public/content/animationStar/star-8.svg");
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 2;
}
.itemsBlockContainer:hover .itemsBlockImgStar {
  width: 30%;
  height: 30%;
  transform: translate(500%, 240%);
  opacity: 1;
}
.itemsBlockContainer > p {
  font: var(--headingThird);
  font-feature-settings: "cv11" on, "cv02" on, "cv03" on, "cv04" on;
  color: var(--white);

  @media (max-width: (3839px)) {
    font: var(--bodyP2);
  }
  @media (max-width: (2159px)) {
  }
  @media (max-width: (1599px)) {
    font: var(--bodyP2);
  }
}

.subtitle {
  margin-left: 4vw;
  font: var(--headingFirst);
  color: var(--white);

  @media (max-width: (3839px)) {
    font: var(--headingFourth);
  }

  @media (max-width: (2159px)) {
    font: var(--headingSixth);
  }

  @media (max-width: (767px)) {
    margin-left: 0;
  }
}

.text {
  margin-left: 4vw;
  color: var(--lightgray);
  font: var(--headingFourth);

  @media (max-width: (3839px)) {
    font: var(--bodyP1);
  }

  @media (max-width: (2159px)) {
    font: var(--bodyP2);
  }

  @media (max-width: (1199px)) {
    margin-left: 0;
  }
}

.testimonial {
  display: flex;
  flex-direction: column;
  margin-left: 4vw;
  width: 748px;
  gap: 24px;
  border-radius: 24px;
  padding: 24px;
  box-sizing: border-box;
  background-color: var(--darkgray);
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.4));
  cursor: pointer;

  &:hover .testimonialAuthor {
    color: var(--white);
  }
  &:hover .testimonialText {
    color: var(--lightgray);
  }
  @media (max-width: (3839px)) {
    width: 561px;
    border-radius: 18px;
    padding: 18px;
    gap: 18px;
  }

  @media (max-width: (2159px)) {
    width: 374px;
    padding: 12px;
    border-radius: 12px;
    gap: 12px;
  }

  @media (max-width: (1199px)) {
    display: none;
  }
}

.testimonialAuthor {
  font: var(--bodyB1Login);
  font-feature-settings: "cv11" on, "cv02" on, "cv03" on, "cv04" on;
  color: var(--lightgray);
  transition: 0.3s ease-in-out;

  @media (max-width: (3839px)) {
    font: var(--bodyB1Login);
  }
  @media (max-width: (2159px)) {
    font: var(--bodyB2Login);
  }
}

.testimonialText {
  position: relative;
  font: var(--bodyB1);
  color: var(--gray);
  transition: 0.3s ease-in-out;

  @media (max-width: (3839px)) {
    font: var(--bodyB1);
  }
  @media (max-width: (2159px)) {
    font: var(--bodyB2);
  }
}

.testimonialStarContainer {
  display: flex;
  gap: 18px;

  @media (max-width: (3839px)) {
    gap: 9px;
  }

  @media (max-width: (2159px)) {
    gap: 6px;
  }
}

.testimonialStar {
  width: 36px;
  height: 36px;
  object-fit: contain;

  @media (max-width: (3839px)) {
    width: 24px;
    height: 24px;
  }

  @media (max-width: (2159px)) {
    width: 16px;
    height: 16px;
  }
}
