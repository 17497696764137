.container {
  position: absolute;
  top: 102px;
  right: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;

  @media (max-width: (1599px)) {
    top: 42px;
  }
  @media (max-width: (1199px)) {
    top: 36px;
    right: 24px;
  }
  @media (max-width: (767px)) {
    justify-content: end;
  }
}

.btnContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 15px;
  z-index: 15;
}

.menuContainer {
  &:hover .btn span:nth-child(1) {
    width: 10px;
  }
  &:hover .btn span:nth-child(2) {
    width: 24px;
  }
  &:hover .btn span:nth-child(3) {
    width: 10px;
    margin-left: 14px;
  }
}

.btnText {
  margin-right: 30px;
  font: var(--bodyP1);
  color: var(--white);

  @media (max-width: (2159px)) {
    font: var(--bodyP2);
  }
  @media (max-width: (1599px)) {
    font: var(--bodyP3);
  }
  @media (max-width: (767px)) {
    display: none;
  }
}

.btn {
  display: flex;
  flex-direction: column;
  background: none;
  outline: none;

  span {
    display: inline-block;
    width: 24px;
    height: 2px;
    margin-bottom: 4px;
    background-color: var(--white);
    transition: width 0.3s ease-in-out, margin 0.3s ease-in-out, transform 0.3s ease-in-out;
    opacity: 0.3;
  }

  span:nth-child(2) {
    width: 21px;
  }
}

.background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background-color: black;
  opacity: 0.9;
  z-index: 12;
}

.menuContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background: var(--darkgray);
  overflow: hidden;
  z-index: 13;

  @media (max-width: 1199px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.nav {
  padding-left: 10vw;
  padding-right: 5vw;
  height: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1199px) {
    justify-content: start;
    flex-direction: column;
    width: 540px;
    margin-top: calc(var(--vh, 1vh) * 15);
    padding: 0;
  }
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70%;

  @media (max-width: 1199px) {
    height: 55%;
    margin-bottom: calc(var(--vh, 1vh) * 7);
  }
}

.link {
  width: max-content;
  font: var(--headingZero);
  font-feature-settings: "salt" on;
  color: var(--white);
  transition: transform 0.3s ease-in-out;
  cursor: pointer;

  @media (max-width: (3839px)) {
    font: var(--headingSecond);
  }
  @media (max-width: (2159px)) {
    font: var(--headingThird);
  }
  @media (max-width: (1599px)) {
    font: var(--headingFifth);
  }
  @media (max-width: (1199px)) {
    font: var(--headingFourth);
  }
  @media (max-width: (767px)) {
    font: var(--headingSixth);
  }
}

.login {
  position: absolute;
  right: 72px;
  bottom: calc(var(--vh, 1vh) * 13);
  padding: 0.8em 2.4em calc(0.8em + 0.05em);
  border: 0.2em solid var(--white);
  border-radius: 5em;
  background: transparent;
  color: var(--white);
  text-decoration: none;
  font: var(--bodyB1Login);
  font-feature-settings: "cv11" on, "cv02" on, "cv03" on, "cv04" on;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s, background-color 0.5s, border 0.5s;

  &:hover {
    border: 0.2em solid var(--white);
    background-color: var(--white);
    color: var(--darkgray);
  }

  &:active {
    border: 0.2em solid var(--black);
    opacity: 0.9;
    background-color: var(--black);
    color: var(--white);
  }

  @media (max-width: (3839px)) {
    font: var(--bodyB2Login);
  }
  @media (max-width: (1599px)) {
    font: var(--bodyB3Login);
  }
  @media (max-width: (1199px)) {
    position: static;
    padding: 0.4em 1.2em calc(0.4em + 0.05em);
    border: 0.1em solid var(--white);
    border-radius: 5em;
    font: var(--bodyB1Login);
  }
  @media (max-width: (767px)) {
    position: static;
    padding: 0.8em 5.7em calc(0.8em + 0.05em);
    font: var(--bodyB2Login);
  }
}
