.container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;
  /* padding: 0 72px; */
  padding-bottom: 90px;
  z-index: 2;

  @media (max-width: (1599px)) {
    padding-bottom: 32px;
  }
  @media (max-width: (1199px)) {
    justify-content: center;
    padding: 0;
    padding-bottom: calc(var(--vh, 1vh) * 2);
  }
  @media (max-width: (767px)) {
    padding-bottom: calc(var(--vh, 1vh) * 6);
  }
}

.talk {
  position: relative;
  font: var(--bodyP1);
  color: var(--white);
  width: max-content;

  &::after {
    content: "";
    position: absolute;
    top: 1.3em;
    left: 0;
    width: 0;
    height: 2px;
    background-color: var(--white);
    transition: width 0.5s;
  }

  &:hover::after {
    width: 100%;
    color: var(--white);
  }

  &:hover {
    width: 100%;
    color: var(--white);
  }

  @media (max-width: (3839px)) {
    font: var(--bodyP2);
  }
  @media (max-width: (1599px)) {
    font: var(--bodyP3);
  }
}

.exploreProject {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 281px;
  height: 116px;
  cursor: pointer;

  &:hover .exploreProjectImg {
    transform: translateY(-0.5em);
  }

  &:hover .exploreProjectText {
    opacity: 1;
  }

  @media (max-width: (3839px)) {
    width: 187px;
    height: 72px;
  }
  @media (max-width: (1599px)) {
    width: 141px;
    height: 66px;
  }
  @media (max-width: (767px)) {
    pointer-events: none;
    width: 40px;
    height: 40px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../../../public/content/arrow.svg");
    transform: rotate(90deg);
  }
}
.exploreProjectImg {
  width: 36px;
  height: 64px;
  object-fit: contain;
  transition: transform 0.5s;

  @media (max-width: (3839px)) {
    width: 18px;
    height: 32px;
  }
  @media (max-width: (767px)) {
    display: none;
  }
}
.exploreProjectText {
  font: var(--bodyP1);
  color: var(--white);
  opacity: 0.4;
  transition: opacity 0.5s;

  @media (max-width: (3839px)) {
    font: var(--bodyP2);
  }
  @media (max-width: (1599px)) {
    font: var(--bodyP3);
  }
  @media (max-width: (767px)) {
    display: none;
  }
}

.footerAbout {
  width: 15vw;
  padding: 0 72px;
  cursor: pointer;

  @media (max-width: (1199px)) {
    display: none;
  }
}
