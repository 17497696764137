.reviewContainer {
  position: absolute;
  top: calc(var(--vh, 1vh) * 8);
  width: 1770px;
  right: 4vw;
  display: flex;
  justify-content: space-between;
  filter: drop-shadow(0px 9px 36px rgba(0, 0, 0, 0.4));
  z-index: 4;

  @media (max-width: (3839px)) {
    width: 1180px;
    filter: drop-shadow(0px 6px 24px rgba(0, 0, 0, 0.4));
  }
  @media (max-width: (2159px)) {
    width: 787px;
  }
  @media (max-width: (1199px)) {
    width: 249px;
    height: 321px;
    right: 72px;
  }
  @media (max-width: (767px)) {
    display: none;
  }
}

.reviewBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 560.25px;
  height: 722.25.5px;
  padding: 67.5px 54px;
  border-radius: 45px;
  gap: 27px;
  box-sizing: border-box;
  background: #19191b;
  opacity: 0.8;
  z-index: 6;
  transition: opacity 0.3s ease-in-out;
  pointer-events: visible;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  @media (max-width: (3839px)) {
    width: 373.5px;
    height: 481.5px;
    padding: 45px 36px;
    border-radius: 30px;
    gap: 18px;
  }
  @media (max-width: (2159px)) {
    width: 249px;
    height: 321px;
    padding: 30px 24px;
    border-radius: 20px;
    gap: 12px;
  }
}

.reviewQuotes {
  width: 108px;
  height: 108px;
  object-fit: contain;

  @media (max-width: (3839px)) {
    width: 72px;
    height: 72px;
  }
  @media (max-width: (2159px)) {
    width: 48px;
    height: 48px;
  }
}

.reviewName {
  font: var(--bodyB1Login);
  font-feature-settings: "cv11" on, "cv02" on, "cv03" on, "cv04" on;
  color: var(--lightgray);

  @media (max-width: (3839px)) {
    font: var(--bodyB1Login);
  }
  @media (max-width: (2159px)) {
    font: var(--bodyB2Login);
  }
}

.reviewText {
  height: 315px;
  font: var(--bodyB1);
  color: var(--gray);

  @media (max-width: (3839px)) {
    height: 210px;
    font: var(--bodyB1);
  }
  @media (max-width: (2159px)) {
    height: 140px;
    font: var(--bodyB2);
  }
}

.reviewStarsContainer {
  display: flex;
  gap: 9px;

  @media (max-width: (3839px)) {
    gap: 6px;
  }
  @media (max-width: (2159px)) {
    gap: 6px;
  }
}

.reviewStarsContainer > img {
  width: 36px;
  height: 36px;
  object-fit: contain;

  @media (max-width: (3839px)) {
    width: 24px;
    height: 24px;
  }
  @media (max-width: (2159px)) {
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 1199px) {
  .swiperArrowReviews {
    position: fixed;
    justify-content: space-between;
    left: -46vw;
    top: 240px;
    display: flex;
    width: 120px;
    height: 48px;
    z-index: 1;

    @media (max-width: (767px)) {
      display: none;
    }
  }
  .swiperArrowReviews > button {
    width: 48px;
    height: 48px;
    transition: opacity 0.3s ease-in-out;

    @media (max-width: (767px)) {
      display: none;
    }
  }
  .swiperArrowReviews > button:active {
    opacity: 0.7;
  }
  .swiperArrowReviewsPrev {
    background-size: contain;
    background-image: url("../../../public/content/home/contact/navigate-prev.svg");
  }
  .swiperArrowReviewsNext {
    background-size: contain;
    background-image: url("../../../public/content/home/contact/navigate-next.svg");
  }
}
