.container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  opacity: 1;
  transition: opacity 2s ease-in-out;
  background-color: #19191b;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../../public/content/home/pexels-aleksandar-pasaric.png");
  overflow: hidden;
  z-index: 9999;
}

.hiddenContainer {
  opacity: 0;
}

.grayStar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40vh;
  transition: transform 1s ease-in-out, padding 1s ease-in-out;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../../public/content/big-star-preloader.svg");
  z-index: 1;

  @media (max-width: (1199px)) {
    width: 550px;
    height: 550px;
    padding: 60px;
    box-sizing: border-box;
  }

  @media (max-width: (767px)) {
    width: 90vw;
    height: 90vw;
  }
}

.hiddenContainer .grayStar {
  transform: translate(30%, -50%);
  padding: 30vh;

  @media (max-width: (1199px)) {
    transform: translate(-50%, -30%);
    width: 550px;
    height: 550px;
    padding: 60px;
    box-sizing: border-box;
  }

  @media (max-width: (767px)) {
    width: 80vw;
    height: 80vw;
  }
}

.textConciergeServices {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40vh;
  transition: transform 1s ease-in-out, padding 1s ease-in-out, opacity 1s ease-in-out;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../../../public/content/first-slide-header.svg");
  z-index: 2;

  @media (max-width: (1199px)) {
    width: 550px;
    height: 550px;
    padding: 60px;
    box-sizing: border-box;
  }

  @media (max-width: (767px)) {
    width: 90vw;
    height: 90vw;
  }
}

.hiddenContainer .textConciergeServices {
  transform: translate(-50%, -140%);
  padding: 20vh;
  opacity: 0;

  @media (max-width: (1199px)) {
    transform: translate(-50%, -200%);
    width: 300px;
    height: 300px;
    padding: 60px;
    box-sizing: border-box;
  }
}
.starLeftFirst {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 7vh;
  height: 7vh;
  transform: translate(-432%, -285%);
  transition: transform 1s ease-in-out, width 1s ease-in-out, opacity 0.7s ease-in-out;
  z-index: 5;

  @media (max-width: (1199px)) {
    width: 64px;
    height: 64px;
    transform: translate(-308%, -215%);
  }

  @media (max-width: (767px)) {
    width: 30.72px;
    height: 30.72px;
    transform: translate(-351%, -255%);
  }
}
.hiddenContainer .starLeftFirst {
  width: 5vh;
  transform: translate(-152%, 85%);
  opacity: 0;

  @media (max-width: (1199px)) {
    width: 34px;
    height: 34px;
    transform: translate(-466%, 700%);
  }
  @media (max-width: (767px)) {
    width: 14px;
    height: 14px;
    transform: translate(-566%, 900%);
  }
}
.starLeftSecond {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10vh;
  height: 10vh;
  transform: translate(-632%, 85%);
  transition: transform 1s ease-in-out, width 1s ease-in-out, opacity 0.7s ease-in-out;
  z-index: 5;

  @media (max-width: (1199px)) {
    width: 64px;
    height: 64px;
    transform: translate(-548%, 85%);
  }

  @media (max-width: (767px)) {
    width: 32px;
    height: 32px;
    transform: translate(-481%, 285%);
  }
}
.hiddenContainer .starLeftSecond {
  width: 5vh;
  transform: translate(-52%, 85%);
  opacity: 0;

  @media (max-width: (1199px)) {
    width: 34px;
    height: 34px;
    transform: translate(-566%, 700%);
  }
  @media (max-width: (767px)) {
    width: 14px;
    height: 14px;
    transform: translate(-566%, 900%);
  }
}
.starRightFirst {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 7vh;
  height: 7vh;
  transform: translate(500%, -285%);
  transition: transform 1s ease-in-out, width 1s ease-in-out, opacity 0.7s ease-in-out;
  z-index: 5;

  @media (max-width: (1199px)) {
    width: 38px;
    height: 38px;
    transform: translate(620%, -510%);
  }

  @media (max-width: (767px)) {
    width: 18.24px;
    height: 18.24px;
    transform: translate(620%, -510%);
  }
}
.hiddenContainer .starRightFirst {
  transform: translate(811%, -277%);
  opacity: 0;

  @media (max-width: (1199px)) {
    transform: translate(320%, -50%);
  }
}
.starRightSecond {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6vh;
  height: 6vh;
  transform: translate(750%, 285%);
  transition: transform 1s ease-in-out, width 1s ease-in-out, opacity 0.7s ease-in-out;
  z-index: 5;

  @media (max-width: (1199px)) {
    width: 30px;
    height: 30px;
    transform: translate(900%, 500%);
  }

  @media (max-width: (767px)) {
    width: 14.4px;
    height: 14.4px;
    transform: translate(850%, 810%);
  }
}
.hiddenContainer .starRightSecond {
  transform: translate(900%, -377%);
  opacity: 0;

  @media (max-width: (1199px)) {
    transform: translate(450%, -50%);
  }

  @media (max-width: (767px)) {
    transform: translate(550%, -50%);
  }
}
.exploreProject {
  position: absolute;
  bottom: 90px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 281px;
  height: 116px;
  cursor: pointer;
  z-index: 5;

  &:hover .exploreProjectImg {
    transform: translateY(-0.5em);
  }

  &:hover .exploreProjectText {
    opacity: 1;
  }

  @media (max-width: (3839px)) {
    width: 187px;
    height: 72px;
  }
  @media (max-width: (1599px)) {
    width: 141px;
    height: 66px;
    bottom: 32px;
  }
  @media (max-width: (1199px)) {
    bottom: calc(var(--vh, 1vh) * 2);
  }
  @media (max-width: (767px)) {
    pointer-events: none;
    width: 40px;
    height: 40px;
    bottom: calc(var(--vh, 1vh) * 6);
  }
}
.exploreProjectImg {
  width: 36px;
  height: 64px;
  object-fit: contain;
  transition: transform 0.5s;

  @media (max-width: (3839px)) {
    width: 18px;
    height: 32px;
  }
  @media (max-width: (767px)) {
    width: 100%;
    height: 100%;
    transform: rotate(90deg);
  }
}
.exploreProjectText {
  font: var(--bodyP1);
  color: var(--white);
  opacity: 0.4;
  transition: opacity 0.5s;

  @media (max-width: (3839px)) {
    font: var(--bodyP2);
  }
  @media (max-width: (1599px)) {
    font: var(--bodyP3);
  }
  @media (max-width: (767px)) {
    display: none;
  }
}
