/* пример код-стайла */

.slide {
  display: flex;
  align-items: center;
  background-color: var(--darkgray);

  @media (max-width: (1199px)) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

/* FIRST SLIDE */

.firstSlide {
  position: relative;
}

.firstSlideHead {
  position: absolute;
  top: 90px;
  right: 0;
  left: 0;
  text-align: center;
  width: 100%;
  height: 160px;
  background-image: url("../../../public/content/first-slide-header.svg");
  background-size: 800px 100%;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: (3839px)) {
    top: 90px;
    height: 64px;
    background-size: 320px 100%;
  }
  @media (max-width: (1599px)) {
    top: 40px;
    height: 48px;
    background-size: 240px 100%;
  }
  @media (max-width: (767px)) {
    display: none;
  }
}

.firstSlideRight {
  position: absolute;
  top: 0;
  right: 0;
  width: 100vh;
  height: 100vh;

  @media (max-width: (1199px)) {
    position: static;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: auto;
    transform: translateY(calc(var(--vh, 1vh) * -2));
    filter: brightness(30%);
    z-index: 1;
  }
  @media (max-width: (767px)) {
    transform: translateY(-13%);
  }
}

.firstSlideImg {
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media (max-width: (1199px)) {
    width: 768px;
    height: 768px;
    object-fit: contain;
    object-position: center;
  }
  @media (max-width: (767px)) {
    width: 100%;
  }
}

.slideLeft {
  margin-left: 379px;

  @media (max-width: (3839px)) {
    margin-left: 239px;
  }
  @media (max-width: (2159px)) {
    margin-left: 177px;
  }
  @media (max-width: (1599px)) {
    margin-left: 144px;
  }
  @media (max-width: (1199px)) {
    width: 500px;
    margin: 0;
    margin-top: calc(var(--vh, 1vh) * 15);
    z-index: 2;
  }
  @media (max-width: (767px)) {
    width: 303px;
    margin-top: calc(var(--vh, 1vh) * 7);
  }
}

.firstSlideLeft {
  cursor: pointer;
  width: 1543px;

  @media (max-width: (3839px)) {
    width: 847px;
  }
  @media (max-width: (2159px)) {
    width: 615px;
  }
  @media (max-width: (1599px)) {
    width: 482px;
  }
  @media (max-width: (767px)) {
    width: 303px;
  }
}

.firstSlideNum {
  font: var(--bodyP1);
  font-feature-settings: "cv11" on, "cv02" on, "cv03" on, "cv04" on;
  color: var(--white);

  @media (max-width: (2159px)) {
    font: var(--bodyP2);
  }
  @media (max-width: (1599px)) {
    font: var(--bodyP3);
  }
}

.firstSlideTitle {
  font: var(--headingFourth);
  font-feature-settings: "salt" on, "case" on;
  color: var(--white);
  margin-top: 8px;

  @media (max-width: (3839px)) {
    font: var(--headingFifth);
  }
  @media (max-width: (2159px)) {
    font: var(--headingSixth);
  }
  @media (max-width: (1599px)) {
    font: var(--headingSeventh);
  }
}

.slideSubtitle {
  margin: 8px 0;
  font: var(--headingZero);
  font-feature-settings: "salt" on;
  color: var(--white);
  animation: 2s show ease;

  @media (max-width: (3839px)) {
    font: var(--headingSecond);
  }
  @media (max-width: (2159px)) {
    font: var(--headingThird);
  }
  @media (max-width: (1599px)) {
    font: var(--headingFourth);
  }
  @media (max-width: (767px)) {
    width: 240px;
    font: var(--headingFifth);
  }
}

.firstSlideSecondSubtitle {
  margin-bottom: 0;
  transition: margin 0.3s ease-in-out;
  font: var(--headingZero);
  color: var(--white);

  @media (max-width: (3839px)) {
    font: var(--headingSecond);
  }
  @media (max-width: (2159px)) {
    font: var(--headingThird);
  }
  @media (max-width: (1599px)) {
    font: var(--headingFourth);
  }
  @media (max-width: (767px)) {
    width: 303px;
    font: var(--headingFifth);
  }
}

.firstSlideText {
  font: var(--bodyP1);
  color: var(--white);
  margin-bottom: 0;
  transition: margin 0.3s ease-in-out;

  @media (max-width: (3839px)) {
    font: var(--bodyP2);
  }
  @media (max-width: (2159px)) {
    font: var(--bodyP3);
  }
}

.firstSlideSubtitle {
  width: 100%;
  @media (max-width: (1199px)) {
    margin-bottom: calc(var(--vh, 1vh) * 10);
  }
  @media (max-width: (767px)) {
    margin-bottom: calc(var(--vh, 1vh) * 14);
  }
}

.firstFirstSlideContent {
  top: 50%;
  transform: translateY(-50%);
  left: 379px;
  position: absolute;
  width: 1543px;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;

  @media (max-width: (3839px)) {
    width: 847px;
    left: 239px;
  }
  @media (max-width: (2159px)) {
    width: 615px;
    left: 177px;
  }
  @media (max-width: (1599px)) {
    width: 482px;
    left: 144px;
  }
  @media (max-width: (1199px)) {
    width: 500px;
    margin: 0;
    top: calc(var(--vh, 1vh) * 44);
    z-index: 2;
  }
  @media (max-width: (767px)) {
    top: calc(var(--vh, 1vh) * 47);
    left: 42px;
    width: 303px;
  }
}

.secondFirstSlideContent {
  top: 50%;
  transform: translateY(-50%);
  left: 379px;
  position: absolute;
  width: 1543px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  @media (max-width: (3839px)) {
    width: 847px;
    left: 239px;
  }
  @media (max-width: (2159px)) {
    width: 615px;
    left: 177px;
  }
  @media (max-width: (1599px)) {
    width: 482px;
    left: 144px;
  }
  @media (max-width: (1199px)) {
    width: 500px;
    margin: 0;
    top: calc(var(--vh, 1vh) * 44);
    z-index: 2;
  }
  @media (max-width: (767px)) {
    top: calc(var(--vh, 1vh) * 47);
    left: 42px;
    width: 303px;
  }
}

.firstSlideLeftActive .firstFirstSlideContent {
  opacity: 0;
}
.firstSlideLeftActive .secondFirstSlideContent {
  opacity: 1;
}

.firstSlideLeftActive .firstSlideText {
  margin-bottom: calc(var(--vh, 1vh) * 4);
}

.firstSlideLeftActive .firstSlideSecondSubtitle {
  margin-bottom: calc(var(--vh, 1vh) * 3);
}
/* SECOND SLIDE */

.conciergeServices {
  position: relative;
}
.contentListLeft {
  z-index: 2;
  cursor: default;

  @media (max-width: (3839px)) {
    width: 920px;
  }
  @media (max-width: (1599px)) {
    width: 500px;
  }
  @media (max-width: (767px)) {
    width: 303px;
  }
}
.numSlide {
  position: relative;
  font: var(--headingFourth);
  font-feature-settings: "cv11" on, "cv02" on, "cv03" on, "cv04" on;
  color: var(--white);

  &::after {
    content: "";
    position: absolute;
    right: -115px;
    bottom: -180px;
    width: 400px;
    height: 400px;
    background-image: url("../../../public/content/round-text.svg");
    background-size: contain;
    background-repeat: no-repeat;
    animation-name: spin;
    animation-duration: 10000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    backface-visibility: hidden;
    transform: translateZ(0);
    image-rendering: -webkit-optimize-contrast;
    will-change: transform;
    image-rendering: pixelated;
    z-index: -1;

    @media (max-width: (3839px)) {
      width: 140px;
      height: 140px;
      right: -42px;
      bottom: -55px;
    }
    @media (max-width: (2159px)) {
      right: -53px;
    }
    @media (max-width: (1599px)) {
      bottom: -59px;
      right: -57px;
    }
    @media (max-width: (1199px)) {
    }
    @media (max-width: (767px)) {
      bottom: -75px;
      right: -145px;
    }
  }

  @media (max-width: (3839px)) {
    font: var(--bodyP1);
  }
  @media (max-width: (2159px)) {
    font: var(--bodyP2);
  }
  @media (max-width: (1599px)) {
    font: var(--bodyP3);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: calc(var(--vh, 1vh) * 1.5);

  @media (max-width: (767px)) {
    padding-top: calc(var(--vh, 1vh) * 5);
  }
}

.link {
  width: max-content;
  font: var(--headingFourth);
  transition: transform 0.3s ease-in-out;
  font-feature-settings: "salt" on;
  overflow: hidden;

  @media (max-width: (3839px)) {
    font: var(--headingSixth);
  }
  @media (max-width: (1599px)) {
    font: var(--headingSeventh);
  }
}
.animationLinkBlock {
  display: flex;
  align-items: center;
  transform: translateX(-100px);
  color: var(--lightgray);
  transition: transform 0.5s, color 0.5s ease-in-out;

  &:hover {
    transform: translateX(0);
    color: var(--white);
  }

  @media (max-width: (3839px)) {
    transform: translateX(-50px);
  }
  @media (max-width: (1599px)) {
    transform: translateX(-40px);
  }
}
.linkArrow {
  background-size: contain;
  width: 100px;
  height: 30px;
  margin-bottom: 0.1em;
  background-repeat: no-repeat;
  background-image: url("../../../public/content/arrow.svg");

  @media (max-width: (3839px)) {
    width: 50px;
    height: 15px;
  }
  @media (max-width: (1599px)) {
    width: 40px;
    height: 12px;
  }
}
.contentListRight {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vh;
  height: 100vh;
  z-index: 1;

  @media (max-width: (1199px)) {
    top: calc(var(--vh, 1vh) * 40);
    width: 100%;
    height: 600px;
  }
  @media (max-width: (767px)) {
    display: block;
    top: calc(var(--vh, 1vh) * 20);
    width: 488px;
    height: 600px;
    right: -95px;
  }
}

.contentListRightImg {
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media (max-width: (1199px)) {
    width: 475px;
    height: 452px;
  }
  @media (max-width: (767px)) {
    width: 100%;
    height: 533.81px;
  }
}

/* THIRD SLIDE */

.ticketsWorldwide {
  position: relative;
}

/* FOURTH SLIDE */

.premiumCards {
  position: relative;
}

.slideText {
  font: var(--bodyP1);
  color: var(--lightgray);

  @media (max-width: (2159px)) {
    font: var(--bodyP2);
  }
  @media (max-width: (767px)) {
    font: var(--bodyP3);
  }
}

.slideTextPremiumCards {
  display: flex;
  flex-direction: column;
  padding-top: calc(var(--vh, 1vh) * 1);
  gap: calc(var(--vh, 1vh) * 3);

  @media (max-width: (3839px)) {
    width: 955px;
  }
  @media (max-width: (2159px)) {
    width: 633px;
  }
  @media (max-width: (1599px)) {
    width: 458px;
  }
  @media (max-width: (767px)) {
    width: auto;
    padding-left: 70px;
  }
}

.premiumCardsRight {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: calc(var(--vh, 1vh) * 100);
  height: calc(var(--vh, 1vh) * 100);
  box-sizing: border-box;
  padding-left: 10%;
  z-index: 1;

  @media (max-width: (1199px)) {
    top: inherit;
    bottom: calc(var(--vh, 1vh) * 4);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    padding: 0;
  }
  @media (max-width: (767px)) {
    bottom: 0;
  }
}

.premiumCardsRightImg {
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media (max-width: (1199px)) {
    width: 504px;
    height: 504px;
  }
  @media (max-width: (767px)) {
    width: 100vw;
    height: 100vw;
  }
}

.premiumCardsSubtitle {
  @media (max-width: 767px) {
    width: 250px;
  }
}

/* FIFTH SLIDE */

.iJet {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../../public/content/home/pexels-aleksandar-pasaric.png");
}
.iJetSubtitle {
  width: 1931px;

  @media (max-width: (3839px)) {
    width: 1164px;
  }
  @media (max-width: (2159px)) {
    width: 802px;
  }
  @media (max-width: (1599px)) {
    width: 583px;
  }
  @media (max-width: (1199px)) {
    width: 500px;
  }
  @media (max-width: (767px)) {
    width: 250px;
  }
}
.iJetText {
  padding-top: calc(var(--vh, 1vh) * 4);
  margin-bottom: calc(var(--vh, 1vh) * 10);

  @media (max-width: (3839px)) {
    width: 1000px;
  }
  @media (max-width: (2159px)) {
    width: 693px;
  }
  @media (max-width: (1599px)) {
    width: 500px;
  }
  @media (max-width: (1199px)) {
    width: 448px;
  }
  @media (max-width: (767px)) {
    width: auto;
    padding-left: 69px;
  }
}
.slideButton {
  padding: 0.8em 2.4em calc(0.8em + 0.05em);
  border: 0.2em solid var(--white);
  border-radius: 5em;
  color: var(--white);
  text-decoration: none;
  background: transparent;
  font: var(--bodyB1Login);
  font-feature-settings: "cv11" on, "cv02" on, "cv03" on, "cv04" on;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s, background-color 0.5s, border 0.5s;

  &:hover {
    border: 0.2em solid var(--white);
    background-color: var(--white);
    color: var(--darkgray);
  }

  &:active {
    border: 0.2em solid var(--black);
    opacity: 0.9;
    background-color: var(--black);
    color: var(--white);
  }

  @media (max-width: (1599px)) {
    font: var(--bodyB3Login);
  }
  @media (max-width: (1199px)) {
    font: var(--bodyB1Login);
    padding: 0.4em 1.2em calc(0.4em + 0.05em);
    border: 0.1em solid var(--white);
    border-radius: 5em;
  }
  @media (max-width: (767px)) {
    font: var(--bodyB2Login);
    padding: 0.8em 1em calc(0.8em + 0.05em);
    margin-left: 69px;
  }
}

.iJetRightFirst {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 4%;
  width: calc(var(--vh, 1vh) * 100);
  height: calc(var(--vh, 1vh) * 100);
  box-sizing: border-box;
  z-index: 1;

  @media (max-width: (1199px)) {
    top: inherit;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 0;
    padding-right: 7%;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    box-sizing: border-box;
    z-index: 1;
  }
  @media (max-width: (767px)) {
    bottom: calc(var(--vh, 1vh) * -7);
    right: 5vw;
  }
}

.iJetRightFirstImg {
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media (max-width: (1199px)) {
    width: 567px;
    height: 488px;
  }
  @media (max-width: (767px)) {
    width: 479.39px;
    height: 392px;
  }
}

.iJetRightSecond {
  position: absolute;
  bottom: 0;
  left: 5vw;
  padding-top: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(var(--vh, 1vh) * 100);
  height: calc(var(--vh, 1vh) * 100);
  box-sizing: border-box;
  z-index: -1;

  @media (max-width: (1199px)) {
    display: none;
  }
  @media (max-width: (767px)) {
  }
}
.iJetRightSecondImg {
  width: 150%;
  height: 150%;
  object-fit: contain;
}

.login {
  @media (max-width: 3839px) {
    font: var(--bodyB2Login);
  }
}

/* SIXTH SLIDE */

.motors {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../../public/content/home/pexels-aleksandar-pasaric.png");
}

.motorsSubtitle {
  width: 2015px;

  @media (max-width: (3839px)) {
    width: 1261px;
  }
  @media (max-width: (2159px)) {
    width: 800px;
  }
  @media (max-width: (1599px)) {
    width: 604px;
  }
  @media (max-width: (1199px)) {
    width: 478px;
  }
  @media (max-width: (767px)) {
    width: 303px;
  }
}

.motorsText {
  display: flex;
  flex-direction: column;
  gap: calc(var(--vh, 1vh) * 3);
  padding-top: calc(var(--vh, 1vh) * 4);
  margin-bottom: calc(var(--vh, 1vh) * 8);

  @media (max-width: (1599px)) {
    width: 410px;
  }
  @media (max-width: (1199px)) {
    gap: calc(var(--vh, 1vh) * 2);
    padding-top: calc(var(--vh, 1vh) * 0.5);
    margin-bottom: calc(var(--vh, 1vh) * 4);
  }
  @media (max-width: (767px)) {
    width: auto;
    padding-left: 69px;
    padding-top: calc(var(--vh, 1vh) * 1);
  }
}

.motorsRight {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: calc(var(--vh, 1vh) * 100);
  height: calc(var(--vh, 1vh) * 100);
  box-sizing: border-box;
  padding-left: 5%;
  z-index: -1;

  @media (max-width: (1199px)) {
    position: absolute;
    top: inherit;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    box-sizing: border-box;
    padding-left: 5%;
    z-index: -1;
  }
  @media (max-width: (767px)) {
    position: absolute;
    top: inherit;
    bottom: calc(var(--vh, 1vh) * -6);
    right: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    box-sizing: border-box;
    padding-left: 5%;
    z-index: -1;
  }
}
.motorsRightImg {
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media (max-width: (1199px)) {
    width: 600px;
    height: 600px;
    object-fit: contain;
  }
  @media (max-width: (767px)) {
    width: 470px;
    height: 470px;
    object-fit: contain;
  }
}
.motorsRightLogo {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: calc(var(--vh, 1vh) * 100);
  height: calc(var(--vh, 1vh) * 100);
  box-sizing: border-box;
  padding: calc(var(--vh, 1vh) * 24);
  padding-left: calc(var(--vh, 1vh) * 40);
  z-index: 1;

  @media (max-width: (1199px)) {
    position: absolute;
    top: inherit;
    bottom: calc(var(--vh, 1vh) * 0);
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 60);
    box-sizing: border-box;
    padding: 21%;
    z-index: 1;
  }
  @media (max-width: (767px)) {
    position: absolute;
    top: inherit;
    bottom: calc(var(--vh, 1vh) * 0);
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 50);
    box-sizing: border-box;
    padding: 0;
    z-index: 1;
  }
}
.motorsRightLogoImg {
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media (max-width: (767px)) {
    width: 40%;
    height: 40%;
    object-fit: contain;
  }
}

/* SEVENTH SLIDE */

.experience {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../../public/content/home/pexels-aleksandar-pasaric.png");
}

.experienceText {
  display: flex;
  flex-direction: column;
  padding-top: calc(var(--vh, 1vh) * 1);
  gap: calc(var(--vh, 1vh) * 3);
  width: 1000px;

  @media (max-width: (3839px)) {
    width: 800px;
  }
  @media (max-width: (2159px)) {
    width: 450px;
  }
  @media (max-width: (767px)) {
    width: auto;

    & > .slideText:nth-child(2) {
      padding-left: 80px;
    }
    & > .slideText:nth-child(3) {
      padding-left: 150px;
    }
  }
}

.experienceRight {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: calc(var(--vh, 1vh) * 100);
  height: calc(var(--vh, 1vh) * 100);
  box-sizing: border-box;
  padding: 8%;
  padding-right: 0;
  padding-left: 13%;
  z-index: -1;

  @media (max-width: (1199px)) {
    position: absolute;
    top: inherit;
    bottom: calc(var(--vh, 1vh) * 5);
    right: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100vw;
    height: 438px;
    box-sizing: border-box;
    padding: 0;
    z-index: -1;
  }
  @media (max-width: (767px)) {
    width: 447px;
  }
}
.experienceRightImg {
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media (max-width: (1199px)) {
    width: 447px;
    height: 438px;
  }
}

/* EIGHTH SLIDE */

.contact {
  position: relative;
}

.contactTitle {
  position: absolute;
  top: calc(var(--vh, 1vh) * 10);
  left: 72px;
  width: 500px;
  font: var(--headingThird);
  color: var(--white);

  @media (max-width: (3839px)) {
    width: 350px;
    font: var(--headingFourth);
  }
  @media (max-width: (1599px)) {
    font: var(--headingFifth);
  }
  @media (max-width: (767px)) {
    display: none;
  }
}
