.transitionWrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--darkgray);
  z-index: 11;
}
.background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background-color: black;
  opacity: 0.9;
  z-index: 12;
}

.transitionContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--darkgray);
  overflow: hidden;
  z-index: 13;
}
