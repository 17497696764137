/* 2x */
.wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 720px;
  z-index: 4;

  @media (max-width: (3839px)) {
    height: 540px;
  }
  @media (max-width: (2159px)) {
    height: 360px;
  }
  @media (max-width: (1199px)) {
    height: auto;
  }
  @media (max-width: (767px)) {
    height: calc(var(--vh, 1vh) * 100);
  }
}
.container {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 72px;
  box-sizing: border-box;

  @media (max-width: (1199px)) {
    flex-direction: column;
    align-items: start;
    justify-content: inherit;
    padding-top: 30px;
    gap: 30px;
  }
  @media (max-width: (767px)) {
    padding: 0;
    padding: calc(var(--vh, 1vh) * 5) 0;
    flex-direction: column;
    align-items: start;
    justify-content: space-around;
    gap: calc(var(--vh, 1vh) * 4);
  }
}
.mapBackground {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  backdrop-filter: blur(20px);
  transition: background-image 0.3s ease-in-out, opacity 0.3s ease-in-out;
  z-index: -1;

  @media (max-width: (767px)) {
    top: inherit;
    bottom: 0;
    height: 24%;
  }
}
.imgLondon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../../public/content/map/London.jpg");
  opacity: 0.8;
}
.imgAmsterdam {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../../public/content/map/Amsterdam\ .jpg");
  opacity: 0.8;
}
.imgBahamas {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../../public/content/map/Bahamas.jpg");
  opacity: 0.8;
}
.mapLeft {
  display: flex;
  flex-direction: column;
  width: 1238px;
  gap: 32px;

  @media (max-width: (3839px)) {
    width: 1038px;
    gap: 24px;
  }
  @media (max-width: (2159px)) {
    width: 519px;
    gap: 16px;
  }
  @media (max-width: (1199px)) {
    width: 624px;
  }
  @media (max-width: (767px)) {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: calc(var(--vh, 1vh) * 3);
  }
}
.leftTitleFirst {
  font: var(--specialsR1);
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: var(--white);

  @media (max-width: (3839px)) {
    font: var(--specialsR2);
  }
  @media (max-width: (2159px)) {
    font: var(--specialsR3);
  }
  @media (max-width: (767px)) {
    padding: 0 24px;
    font: var(--specialsR2);
  }
}
.leftTitleSecond {
  font: var(--headingSecond);
  font-feature-settings: "salt" on, "case" on;
  color: var(--white);

  @media (max-width: (3839px)) {
    font: var(--headingThird);
  }
  @media (max-width: (2159px)) {
    font: var(--headingFourth);
  }
  @media (max-width: (767px)) {
    padding: 0 24px;
    font: var(--headingFifth);
  }
}
.leftText {
  font: var(--bodyP1);
  font-feature-settings: "cv11" on, "cv02" on, "cv03" on, "cv04" on;
  color: var(--lightgray);

  @media (max-width: (3839px)) {
    font: var(--bodyP2);
  }
  @media (max-width: (2159px)) {
    font: var(--bodyP3);
  }
  @media (max-width: (767px)) {
    padding: 0 24px;
    font: var(--bodyP2);
  }
}
.mapRight {
  width: 740px;
  display: flex;
  flex-direction: column;
  gap: 64px;

  @media (max-width: (3839px)) {
    width: 555px;
    gap: 48px;
  }
  @media (max-width: (2159px)) {
    width: 370px;
    gap: 32px;
  }
  @media (max-width: (1199px)) {
    width: 370px;
    gap: 32px;
    padding-bottom: 30px;
  }
  @media (max-width: (767px)) {
    width: 100%;
    padding-bottom: 0;
    gap: calc(var(--vh, 1vh) * 3);
  }
}
.rightTitle {
  font: var(--specialsR2);
  text-transform: uppercase;
  color: var(--white);
  letter-spacing: 0.2em;

  @media (max-width: (3839px)) {
    font: var(--specialsR3);
  }
  @media (max-width: (2159px)) {
    font: var(--specialsR4);
  }
  @media (max-width: (767px)) {
    padding: 0 24px;
    text-align: center;
    font: var(--specialsR2);
  }
}
.buttonWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: (3839px)) {
    gap: 9px;
  }
  @media (max-width: (2159px)) {
    gap: 6px;
  }
  @media (max-width: (767px)) {
    padding: 0 24px;
    padding-top: calc(var(--vh, 1vh) * 1.5);
    padding-bottom: calc(var(--vh, 1vh) * 1);
    gap: calc(var(--vh, 1vh) * 3);
    background-color: var(--black);
  }
}
.buttonContainer {
  display: flex;
  gap: 40px;

  @media (max-width: (3839px)) {
    gap: 30px;
  }
  @media (max-width: (767px)) {
    justify-content: space-between;
    gap: 0px;
  }
}
.buttonMap {
  padding: 0.5em 2em calc(0.45em + 0.05em);
  border: 0.2em solid var(--white);
  border-radius: 5em;
  color: var(--white);
  text-decoration: none;
  background: transparent;
  font: var(--bodyB1Login);
  font-feature-settings: "cv11" on, "cv02" on, "cv03" on, "cv04" on;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s, background-color 0.5s, border 0.5s;

  @media (max-width: (3839px)) {
    font: var(--bodyB2Login);
  }
  @media (max-width: (2159px)) {
    font: var(--bodyB3Login);
  }
  @media (max-width: (767px)) {
    padding: 0.2em 1.2em calc(0em + 0.05em);
  }
}
.buttonMapActive {
  border: 0.2em solid var(--white);
  background-color: var(--white);
  color: var(--darkgray);
}

.addressOnMap {
  position: relative;
  min-height: 72px;
  max-height: 72px;
  font: var(--bodyP1);
  font-feature-settings: "cv11" on, "cv02" on, "cv03" on, "cv04" on;
  color: var(--white);
  transition: text-decoration 0.3 ease-in-out;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: (2159px)) {
    min-height: 48px;
    height: 48px;
    max-height: 48px;
    font: var(--bodyP2);
  }
  @media (max-width: (767px)) {
    text-align: center;
  }
}
.feedback {
  display: flex;
  gap: 64px;

  @media (max-width: (3839px)) {
    gap: 48px;
  }
  @media (max-width: (2159px)) {
    gap: 32px;
  }
  @media (max-width: (767px)) {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0 40px;
  }
}
.feedbackContainer a {
  font: var(--bodyP1);
  font-feature-settings: "cv11" on, "cv02" on, "cv03" on, "cv04" on;
  color: var(--white);
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.9;
  }

  @media (max-width: (3839px)) {
    font: var(--bodyP1);
  }
  @media (max-width: (2159px)) {
    font: var(--bodyP2);
  }
}
.feedbackText {
  margin-bottom: 16px;
  font: var(--specialsR2);
  text-transform: uppercase;
  color: var(--white);
  letter-spacing: 0.2em;

  @media (max-width: (3839px)) {
    margin-bottom: 12px;
    font: var(--specialsR3);
  }
  @media (max-width: (2159px)) {
    margin-bottom: 8px;
    font: var(--specialsR4);
  }
  @media (max-width: (1199px)) {
    margin-bottom: 8px;
    font: var(--specialsR4);
  }
  @media (max-width: (767px)) {
    margin-bottom: calc(var(--vh, 1vh) * 0.5);
    font: var(--specialsR4);
  }
}
.phoneBlock {
  display: flex;
  gap: 32px;

  @media (max-width: (3839px)) {
    gap: 24px;
  }
  @media (max-width: (2159px)) {
    gap: 16px;
  }
  @media (max-width: (767px)) {
    flex-direction: column;
    gap: 0px;
  }
}
.copyright {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-top: 0.2em;
  box-sizing: border-box;
  text-align: center;
  font-feature-settings: "cv11" on, "cv02" on, "cv03" on, "cv04" on;
  font: var(--bodyP2);
  color: var(--lightgray);
  background: rgba(0, 0, 0, 0.4);
  // opacity: 0.4;

  a {
    opacity: 0.4;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }

  @media (max-width: (3839px)) {
    font: var(--bodyP2);
  }
  @media (max-width: (2159px)) {
    font: var(--bodyP3);
  }
  @media (max-width: (1199px)) {
    font: var(--bodyP3);
  }
}
