@font-face {
    font-family: 'TTFirsNeue';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url('../public/fonts/TTFirsNeue-Regular.woff2') format('woff2')
  }
  @font-face {
    font-family: 'TTFirsNeue';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: url('../public/fonts/TTFirsNeue-Medium.woff2') format('woff2')
  }
  @font-face {
    font-family: 'TTFirsNeue';
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src: url('../public/fonts/TTFirsNeue-DemiBold.woff2') format('woff2')
  }
  @font-face {
    font-family: 'TTFirsNeue';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: url('../public/fonts/TTFirsNeue-Bold.woff2') format('woff2')
  }
  @font-face {
    font-family: 'Gopher';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: url('../public/fonts/GopherDisplay-Light.woff2') format('woff2')
  }
  @font-face {
    font-family: 'Gopher';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url('../public/fonts/GopherDisplay-Regular.woff2') format('woff2')
  }
  @font-face {
    font-family: 'Gopher';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: url('../public/fonts/GopherDisplay-Bold.woff2') format('woff2')
  }
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('../public/fonts/inter-regular.woff2') format('woff2') /* Super Modern Browsers */
  }
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url('../public/fonts/inter-500.woff2') format('woff2')/* Super Modern Browsers */
  }
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: url('../public/fonts/inter-600.woff2') format('woff2') /* Super Modern Browsers */
  }
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: url('../public/fonts/inter-700.woff2') format('woff2') /* Super Modern Browsers */
  }
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
  }
  html {
    box-sizing: border-box;
    line-height: 1.15; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
  }
  body {
    position: relative;
    margin: 0;
    box-sizing: border-box;
    line-height: 1.6;
    font-family: 'TTFirsNeue', Arial, Helvetica, sans-serif;
    font-weight: 400;
    overscroll-behavior-y: none;
    overflow: hidden;
  }
  main {
    display: block;
  }
  h1 {
    font-size: 2em;
  }
  hr {
    box-sizing: content-box; /* 1 */
    height: 0; /* 1 */
    overflow: visible; /* 2 */
  }
  pre {
    font-family: monospace, monospace; /* 1 */
    font-size: 1em; /* 2 */
  }
  a {
    background-color: transparent;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }
  abbr[title] {
    border-bottom: none; /* 1 */
    text-decoration: underline; /* 2 */
    text-decoration: underline dotted; /* 2 */
  }
  b,
  strong {
    font-weight: bolder;
  }
  code,
  kbd,
  samp {
    font-family: monospace, monospace; /* 1 */
    font-size: 1em; /* 2 */
  }
  small {
    font-size: 80%;
  }
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  img {
    border-style: none;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit; /* 1 */
    font-size: 100%; /* 1 */
    line-height: 1.15; /* 1 */
    margin: 0; /* 2 */
  }
  input {
    padding: 0;
    background-color: transparent;
    border: none;
  }
  input:focus-visible {
    outline: none;
  }
  button,
  input { /* 1 */
    overflow: visible;
  }
  button {
    cursor: pointer;
    padding: 0;
    background-color: transparent;
    border: none;
  }
  button,
  select { /* 1 */
    text-transform: none;
  }
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  fieldset {
    padding: 0.35em 0.75em 0.625em;
  }
  legend {
    box-sizing: border-box; /* 1 */
    color: inherit; /* 2 */
    display: table; /* 1 */
    max-width: 100%; /* 1 */
    padding: 0; /* 3 */
    white-space: normal; /* 1 */
  }
  progress {
    vertical-align: baseline;
  }
  textarea {
    overflow: auto;
  }
  [type="checkbox"],
  [type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
  }
  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }
  [type="search"] {
    -webkit-appearance: textfield; /* 1 */
    outline-offset: -2px; /* 2 */
  }
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-file-upload-button {
    -webkit-appearance: button; /* 1 */
    font: inherit; /* 2 */
  }
  ::selection {
    background: gray;
    color: white;
  }
  details {
    display: block;
  }
  summary {
    display: list-item;
  }
  template {
    display: none;
  }
  [hidden] {
    display: none;
  }
  ul {
   margin: 0;
   padding: 0;
   list-style-type: none;
  }
  * {
    -webkit-tap-highlight-color: transparent;
  }
  :root {
    --white: #fff;
    --lightgray: #9BA0A7;
    --gray: #424951;
    --darkgray: #19191B;
    --black: #000000;
    --goldFirst: #F2D17A;
    --goldSecond: #E4B862;
    --goldThird: #D5C8AA;
    --lightFirst: #F8F0E0;
    --lightSecond: #FEDBC7;
    --darkFirst: #3F3F3F;
    --darkSecond: #684049;
    --darkThird: #635B64;
    --darkFourth: #39384A;
    --darkFifth: #2E2F41;
    --darkSixth: #50607D;
    --headingZero: 400 160px/192px 'TTFirsNeue';
    --headingFirst: 600 128px/153.6px 'TTFirsNeue';
    --headingSecond: 400 96px/115.2px 'TTFirsNeue';
    --headingThird: 400 64px/76.8px 'TTFirsNeue';
    --headingFourth: 400 48px/57.6px 'TTFirsNeue';
    --headingFifth: 400 32px/38.4px 'TTFirsNeue';
    --headingSixth: 400 24px/28.8px 'TTFirsNeue';
    --headingSeventh: 400 20px/24px 'TTFirsNeue';
    --headingEighth: 600 16px/19.2px 'TTFirsNeue';
    --bodyB1Login: 700 24px/36px 'TTFirsNeue';
    --bodyB2Login: 700 16px/24px 'TTFirsNeue';
    --bodyB3Login: 700 12px/18px 'TTFirsNeue';
    --bodyB1: 500 24px/36px 'TTFirsNeue';
    --bodyB2: 500 16px/24px 'TTFirsNeue';
    --bodyB3: 500 12px/18px 'TTFirsNeue';
    --bodyP1: 400 24px/36px 'TTFirsNeue';
    --bodyP2: 400 16px/24px 'TTFirsNeue';
    --bodyP3: 400 12px/18px 'TTFirsNeue';
    --specialsR1: 400 24px/36px 'Gopher';
    --specialsR2: 400 16px/24px 'Gopher';
    --specialsR3: 300 12px/18px 'Gopher';
    --specialsR4: 300 10px/15px 'Gopher';
    --specialsB1: 700 24px/36px 'Gopher';
    --specialsB2: 700 16px/24px 'Gopher';
  }

  .burgerOpen span:nth-child(1) {
    transform: rotate(45deg) translate(2px, 0px);
    width: 10px;
  }

  .burgerOpen span:nth-child(2) {
    transform: rotate(-45deg);
    width: 24px;
  }

  .burgerOpen span:nth-child(3) {
    transform: rotate(45deg) translate(-4px, 1.7px);
    width: 11px;
    margin-left: 14px;
  }

  /* swiper pagination*/

  .swiper-pagination-vertical.swiper-pagination-bullets, .swiper-vertical>.swiper-pagination-bullets {
    top: 46%;
    left: 65px;
    right: inherit;
    width: 24px;
    height: 24px;
    transform: translate3d(0px,-50%,0);
  }

  .swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-vertical>.swiper-pagination-bullets>span {
    position: relative;
    background-color: transparent;
    transform: rotate(270deg);
    margin-bottom: 30px;
    color: var(--white);
  }
  .swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-vertical>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin-bottom: 30px;
  }

  .swiper-pagination-bullet-active  {
    opacity: 0.7;
  }

  .swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-vertical>.swiper-pagination-bullets>span:nth-child(1)::before {
    content:"01" ;
  }
  .swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-vertical>.swiper-pagination-bullets>span:nth-child(2)::before {
    content:"02" ;
  }
  .swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-vertical>.swiper-pagination-bullets>span:nth-child(3)::before {
    content:"03" ;
  }
  .swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-vertical>.swiper-pagination-bullets>span:nth-child(4)::before {
    content:"04" ;
  }
  .swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-vertical>.swiper-pagination-bullets>span:nth-child(5)::before {
    content:"05" ;
  }
  .swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-vertical>.swiper-pagination-bullets>span:nth-child(6)::before {
    content:"06" ;
  }
  .swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-vertical>.swiper-pagination-bullets>span:nth-child(7)::before {
    content:"07" ;
  }
  .swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-vertical>.swiper-pagination-bullets>span:nth-child(8)::before {
    content:"08" ;
  }

  @media (max-width: 3839px) {
    .swiper-pagination-vertical.swiper-pagination-bullets, .swiper-vertical>.swiper-pagination-bullets {
      top: 40%;
    }
  }

  @media (max-width: 2159px) {

  }

  @media (max-width: 1599px) {

  }

  @media (max-width: 1199px) {
    .swiper-pagination-vertical.swiper-pagination-bullets, .swiper-vertical>.swiper-pagination-bullets {
      top: 24.3%;
    }
    .swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-vertical>.swiper-pagination-bullets>span {
      margin-bottom: 35px;
    }
    .swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-vertical>.swiper-pagination-bullets .swiper-pagination-bullet {
      margin-bottom: 35px;
    }
  }

  @media (max-width: 767px) {
    .swiper-pagination-vertical.swiper-pagination-bullets, .swiper-vertical>.swiper-pagination-bullets {
      display: none;
      transform: translateX(-100vw);
    }
  }


  .loader-block {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.85);
  }

  .loader-circle {
    min-width: 100px;
    height: 100px;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    border-radius: 50%;
    border-top: 10px solid rgba(255, 255, 255, 0.2);
    border-right: 10px solid rgba(255, 255, 255, 0.2);
    border-bottom: 10px solid rgba(255, 255, 255, 0.2);
    border-left: 10px solid rgba(255, 255, 255, 1);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: circle-loader-spin 1s infinite linear;
    animation: circle-loader-spin 1s infinite linear;
  }

  @keyframes circle-loader-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .active {
    background: rgba(215, 194, 154, 0.1);
  }

  .active::after {
    border-color: #D7C29A !important;
  }





.swiperHome {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
}

.swiperHome .swiper-slide-active .translateAnimationFirst{
    animation: fadeInUpSD 0.5s both 0.5s
}
.swiperHome .swiper-slide-active .translateAnimationSecond{
    animation: fadeInUpSD 0.6s both 0.6s
}
.swiperHome .swiper-slide-active .translateAnimationThird{
    animation: fadeInUpSD 0.7s both 0.7s
}
.swiperHome .swiper-slide-active .translateAnimationFourth{
    animation: fadeInUpSD 0.8s both 0.8s
}
.swiperHome .swiper-slide-active .translateAnimationFifth{
    animation: fadeInUpSD 0.9s both 0.9s
}

@keyframes fadeInUpSD {
  0% {
      opacity: 0;
      transform: translate3d(5%,0,0)
  }
  100% {
      opacity: 1;
      transform: translate3d(0,0,0)

  }
}
